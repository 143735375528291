<template>
    <div class='headbox'>
        <div v-if="isback" class="iconbox">
            <i class="el-icon-arrow-left iicon" ></i>
            <span class="icon-span">返回</span>
        </div>
        <div v-else></div>
        <p class="titles">{{ title }}</p>
        
        <div v-if="isback" class="right-element">

        </div>
        <div v-else class="r">

</div>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
    // import引入的组件需要注入到对象中才能使用
    props: {
        title:{
            type:String,
            default:''
        },
        isback:{
            type:Boolean,
            default:false
        }
    },
    components: {},
    data() {
        // 这里存放数据
        return {

        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 方法集合
    methods: {

    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
// @import url(); 引入公共css类
.headbox {
    width: 100vw;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #3d88f5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    .titles {
        padding: 0;
        margin: 0;
        min-width: 50px;
        text-align: center;
        font-size: 18px;
        color: #fff;
    }
    .iconbox{
        display: flex;
        align-items: center;

        .icon-span{
            font-size: 18px;
        }
    }
    .iicon{
        font-size: 20px;
    }
    .right-element{
        width: 50px;
    }
}
</style>