<template>
  <div class="home" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <!-- 公共头部 -->
    <publicHead title="我的监控" :isback="false"></publicHead>
    <!-- 选择房间 -->
    <div class="selectRoombox">
      <div class="selectRoom">
        <span class="selectRoomSpan">请选择房间：</span>
        <el-select style="border:none; width:65%;height:99%;" @change="handleSelect" v-model="currentRoom" placeholder="请选择房间">
          <el-option v-for="item in selectList" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- 循环摄像头 -->
    <div v-if="!isbig">
      <div class="imgbox_box" v-for="item in camerList" :key="item.name" @click="handleDoubleClick('to-big', item)">
        <div class="imgbox_">
          <div class="td-name">
            <p>{{ item.name }}</p>
            <p :ref="`camerName-${item.id}`" class="namec"></p>
            <p :ref="`camerTime-${item.id}`"></p>
          </div>
          <div class="imgbox">
            <img :ref="`imgs-${item.id}`" v-show="isShowImg" class="wsImg" alt="">
            <svg :ref="`img-svg-${item.id}`" class="img-svg" xmlns="http://www.w3.org/2000/svg">
            </svg>
          </div>
        </div>
      </div>

    </div>

    <!-- 单个视频放大 -->
    <div class="bigbox" v-else>
      <div class="imgbox_box" @click="handleDoubleClick('to-list')">
        <div class="imgbox_">
          <div class="td-name">
            <p>{{ bigData.name }}</p>
            <p :ref="`camerName-${bigData.id}`" class="namec"></p>
            <p :ref="`camerTime-${bigData.id}`"></p>
          </div>
          <div class="imgbox">
            <img :ref="`imgs-${bigData.id}`" v-show="isShowImg" class="wsImg" alt="">
            <svg :ref="`img-svg-${bigData.id}`" class="img-svg" xmlns="http://www.w3.org/2000/svg">
            </svg>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import mqtt from "mqtt";
import publicHead from "@/components/publicHead.vue"
export default {
  name: "HomeView",
  components: {
    publicHead
  },
  data() {
    return {
      phone: '13688143995',
      loading: false,
      currentRoom: "",
      currentRoom_: "",
      selectList: [],
      isbig: false,
      bigData: {},
      client: null,
      imgurl: "",
      isShowImg: true,
      imgWidth: 0,
      imgHeight: 0,
      typeColorObj: {
        '人体': '#cc45e4',
        '摩托车': '#f6fb7b',
        '奶牛': 'blue',
        '马': '#3eb64d'
      },
      camerName: {

      },
      camerList: [
        {
          name: '通道一',
          id: 1
        },
        {
          name: '通道二',
          id: 2
        },
        {
          name: '通道三',
          id: 3
        },
        {
          name: '通道四',
          id: 4
        }
      ]
    };
  },
  created() {
    //用户是否登录
    // this.isLogin()
    //连接ws
    this.connectMQTT();
  },
  methods: {
    //选择房间
    handleSelect(roomid) {
      console.log(this.phone + '/room/' + roomid + '/#');
      //存起来当前选择的房间，连接的时候要用
      this.currentRoom_ = this.phone + '/room/' + roomid + '/#'
      // 组件销毁前断开MQTT连接
      if (this.client) {
        this.client.end();
      }
      this.connectMQTT()
    },
    // 定义双击事件的处理方法  
    handleDoubleClick(type, data) {
      if (type == 'to-big') {
        this.isbig = true
        this.bigData = data

      } else if (type == 'to-list') {
        this.isbig = false
      }

      // 在这里编写你的逻辑  
      console.log('图片被双击了！');
      // 例如，你可以在这里切换一个数据属性来显示放大的图片  
      // this.showEnlargedImage = true;  
    },
    isLogin() {
      let token_ = localStorage.getItem('token_')
      if (!token_) {
        this.$router.push('/LoginPage')
      }
    },
    connectMQTT() {
      const options = {
        connectTimeout: 4000,
        clientId: "10086",
        port: 8083,
      };
      this.client = mqtt.connect("ws://weisungtech.com/mqtt", options);

      this.client.on("connect", () => {
        console.log("Connected正在连接");
        this.loading = true
        let connectList
        //如果有已经选择的房间name，就把当前选的房间加进去到连接主题列表
        if (this.currentRoom_) {
          connectList = [`${this.phone}/roomname`, this.currentRoom_]
        } else {
          connectList = [`${this.phone}/roomname`]
        }

        //主题列表 //房间名字和房间内容
        connectList.forEach(item => {
          // 订阅主题
          this.client.subscribe(item, err => {
            if (!err) {
              setTimeout(() => {
                this.loading = false
              }, 1000);
              console.log("Subscribed订阅成功");
            }
          });
        })
      });

      this.client.on("message", (topic, message) => {
        // 收到消息时的回调
        // console.log(1);
        this.loading = false
        if (topic.includes('roomname')) {                     //房间
          //打印接收数据的类型
          // let data = JSON.parse(message.toString())

          // console.log(data);
          let data = message.toString()

          // console.log(data);
          //如果下拉列表里面不包含当前房间DATA，就push到下拉列表里面
          let startIndex = data.indexOf('room/')
          let str = data.slice(startIndex + 5, -2)
          if (!this.selectList.includes(str)) {
            this.selectList.push(str)
          }
          console.log(this.selectList, 'this.selectList');

        } else {                                              //消息
          //打印接收数据的类型
          let data = JSON.parse(message.toString())
          console.log(data);
          //存储相机名称
          this.camerName = {}
          //判断大小屏
          let currentCamerBig
          let currentImg
          let currentCamer
          let currentCamerTime
          if (this.isbig && topic[topic.length - 1] == this.bigData.id) {
            currentCamerBig = this.bigData.id
            //判断是哪个通道的图片
            currentImg = this.$refs[`imgs-${currentCamerBig}`]
            this.imgurl = 'data:image/jpeg;base64,' + data.eventImgNomash
            if (currentImg) {
              currentImg.src = this.imgurl
            }

            //相机名字
            currentCamer = this.$refs[`camerName-${currentCamerBig}`]
            if (currentCamer) {
              currentCamer.innerHTML = data.monitorPoint

            }

            //相机时间
            currentCamerTime = this.$refs[`camerTime-${currentCamerBig}`]
            if (data.eventObjects.length) {
              if (currentCamerTime) {
                currentCamerTime.innerHTML = data.eventObjects[0].eventTime

              }
            }
            console.log(data, 'this.addSvg(data)');
            this.addSvg(data)
          } else {
            currentCamerBig = topic[topic.length - 1]
            //判断是哪个通道的图片
            let currentImg = this.$refs[`imgs-${currentCamerBig}`][0]
            this.imgurl = 'data:image/jpeg;base64,' + data.eventImgNomash
            if (currentImg) {
              currentImg.src = this.imgurl
            }

            //相机名字
            currentCamer = this.$refs[`camerName-${currentCamerBig}`][0]
            if (currentCamer) {
              currentCamer.innerHTML = data.monitorPoint

            }

            //相机时间
            currentCamerTime = this.$refs[`camerTime-${currentCamerBig}`][0]
            if (data.eventObjects.length) {
              if (currentCamerTime) {
                currentCamerTime.innerHTML = data.eventObjects[0].eventTime

              }
            }
            //调用画矩形方法
            if (currentCamerTime) {
              this.addSvg(data)

            }
          }
          //存储图片url变量先显示图片
          // this.imgurl = 'data:image/jpeg;base64,' + data.eventImgNomash

        }






      });

      this.client.on("reconnect", () => {
        console.log("Reconnecting...");
      });

      this.client.on("error", err => {
        console.error("MQTT error:", err);
      });

      this.client.on("close", () => {
        console.log("Connection closed");
      });
    },
    async addSvg(data) {
      //获取到了图片大小 this.imgWidth this.imgHeight
      await this.getImageDimensions(this.imgurl)
      //判断是不是大屏显示图片
      let mySvg_
      let imgele_
      if (this.isbig) {
        // 获取svg
        mySvg_ = this.$refs[`img-svg-${this.bigData.id}`]
        //获取img标签
        imgele_ = this.$refs[`imgs-${this.bigData.id}`]

        console.log(`imgs-${this.bigData.id}`, '画单独框子');
      } else {
        // 获取svg
        mySvg_ = this.$refs[`img-svg-${data.channel[data.channel.length - 1]}`][0]
        //获取img标签
        imgele_ = this.$refs[`imgs-${data.channel[data.channel.length - 1]}`][0]
      }
      let imgele_size = imgele_.getBoundingClientRect()
      let current_img_size = [imgele_size.width, imgele_size.height]
      mySvg_.style.width = current_img_size[0] + 'px'
      mySvg_.style.height = current_img_size[1] + 'px'
      // 获取<g>元素的引用  
      // var myGroup = document.getElementById('myGroup');


      // 移除<g>元素下的所有子元素  
      while (mySvg_.firstChild) {
        mySvg_.removeChild(mySvg_.firstChild);
      }
      // let h = svg.getBoundingClientRect().height
      // 设置<g>元素的transform属性  
      // myGroup.setAttribute('transform', `translate(0, ${h})`);

      //循环需要渲染的框框
      data.eventObjects.forEach(item => {
        let obj = JSON.parse(item.eventContent)
        obj.forEach(redbox => {
          //设定框和线的颜色
          let currentColor = this.typeColorObj[item.eventName]
          if (!currentColor) {
            currentColor = 'red'
          }
          //设置文字内容
          let currentText = item.eventName + redbox.score
          // 比例计算
          let xmin100 = (redbox.xmin / this.imgWidth)
          let ymin100 = (redbox.ymin / this.imgHeight)
          let xmax100 = (redbox.xmax / this.imgWidth)
          let ymax100 = (redbox.ymax / this.imgHeight)
          //判断是不是大屏显示图片
          let imgele
          if (this.isbig) {
            imgele = this.$refs[`imgs-${this.bigData.id}`]

          } else {
            imgele = this.$refs[`imgs-${data.channel[data.channel.length - 1]}`][0]

          }
          // 
          let imgele_size = imgele.getBoundingClientRect()
          let current_img_size = [imgele_size.width, imgele_size.height]
          let xmin_ = current_img_size[0] * xmin100
          let ymin_ = current_img_size[1] * ymin100
          let xmax_ = current_img_size[0] * xmax100
          let ymax_ = current_img_size[1] * ymax100
          // 创建一个矩形
          // 已知的对角坐标点  
          // console.log(xmin_,ymin_,xmax_,ymax_);

          var point1 = { x: xmin_, y: ymin_ };
          var point2 = { x: xmax_, y: ymax_ };
          // var point1 = { x: 100, y: 100 };
          // var point2 = { x: 200, y: 200 };
          // 计算边界框  
          var minX = Math.min(point1.x, point2.x);
          var maxX = Math.max(point1.x, point2.x);
          var minY = Math.min(point1.y, point2.y);
          var maxY = Math.max(point1.y, point2.y);

          // 创建一个新的<rect>元素  
          var rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
          rect.setAttribute('x', minX);
          rect.setAttribute('y', minY);
          rect.setAttribute('width', maxX - minX);
          rect.setAttribute('height', maxY - minY);
          rect.setAttribute('fill', 'transparent'); // 设置填充颜色  
          rect.setAttribute('stroke', currentColor); // 设置描边颜色  
          rect.setAttribute('stroke-width', '2'); // 设置描边颜色  

          // 将<rect>元素添加到SVG中  
          // myGroup.appendChild(rect);
          mySvg_.appendChild(rect);
          // 创建一个新的text元素  
          var text = document.createElementNS('http://www.w3.org/2000/svg', 'text');

          // 设置text元素的属性  
          text.setAttribute('x', minX); // 设置x坐标为100  
          text.setAttribute('y', minY); // 设置y坐标为100  
          text.setAttribute('fill', currentColor); // 设置文字颜色为黑色  
          text.textContent = currentText; // 设置文字内容  

          // 将text元素添加到SVG中  
          mySvg_.appendChild(text);
        })
      })
    },
    //获取图片的大小
    getImageDimensions(base64Image) {
      return new Promise((resolve) => {
        // 创建一个新的Image对象  
        const img = new Image();

        // 当图片加载完成后执行  
        img.onload = () => {
          // 访问图片的宽度和高度  
          this.imgWidth = img.width
          this.imgHeight = img.height
          resolve()
          // 如果你需要将这些值返回给调用者，你可以使用回调函数  
          // 例如：callback(img.width, img.height);  
        };

        // 设置图片的源为Base64编码的字符串  
        // 注意：如果Base64字符串是URL格式的（以data:image/png;base64,开头），则直接赋值  
        // 如果不是，你可能需要将其转换为URL格式  
        img.src = base64Image;

        // 注意：由于图片加载是异步的，以下代码将在图片加载完成前执行  
        // 如果你需要在图片加载完成后做一些处理，确保这些代码放在img.onload内部  
      })

    }
  },
  beforeDestroy() {
    // 组件销毁前断开MQTT连接
    if (this.client) {
      this.client.end();
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep(.el-input__inner) {
  border: none;
}

.home {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  position: relative;

  .selectRoombox {
    width: 100%;
    background: #3d88f5;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 43px;

    .selectRoom {
      width: calc(94% - 20px);
      height: 40px;
      margin: 0 auto;
      display: flex;
      // justify-content: center;
      align-items: center;
      padding-left: 20px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      background: #fff;

      .selectRoomSpan {
        font-size: 16px;
        color: #646262;
      }

    }
  }


  // transform: rotate(90deg); // 关键代码
  .home-title {
    width: 100%;
    height: 50px;
  }

  .imgbox_box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imgbox_ {
    min-width: 97%;
    max-width: 99%;
    min-height: 180px;
    background: #fff;
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);

    .td-name {
      border-radius: 5px 5px 0 0;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 30px;
      background: #4584fcc4;
      display: flex;
      align-items: center;
      color: #fff;

      p {
        padding-left: 20px
      }

      border-bottom: 1px solid #ccc;
    }
  }

  .imgbox {
    width: calc(100% - 2px);
    min-height: 1px;
    display: flex;
    // border: 1px solid #ccc;
    position: relative;

    .wsImg {
      width: 100%;
      height: auto;
    }

    .img-svg {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10
    }
  }

  .bigbox {}
}
</style>